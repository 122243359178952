// Project: GalaxyComplete
// Created: 9/27/20 by sammy
// File: NewDeploymentInstructions

import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    Divider,
    FormControlLabel,
    FormGroup,
    ListItemButton,
    ListItemText,
    Stack,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { useCurrentProject, useCurrentProjectID } from "../project/CurrentProjectState";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import { OperatorView } from "../auth/AuthenticatedViews";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { DiLinux, DiWindows } from "react-icons/di";
import { TabConfig, TabGroup } from "../../common/tabs/TabComponents";
import { getApiHost } from "../grpc/grpcCommon";
import { AppHintID, HintButton } from "../help/HelpCommon";
import { DEFAULT_PORTAL_HOST, DirectIcon, RelayIcon } from "./DeploymentCommon";
import { PortNumbers } from "gc-web-proto/galaxymigratepb/galaxy_migrate_pb";
import { SelectableBox, SelectableCard } from "../../common/card/SelectableCard";
import { DeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useHelpDrawerState } from "../help/hooks/help_hooks";
import { useIsDesktop, useRightSideDrawerWidth } from "../layout/MainLayout";
import { ListGalaxyMigrateDeployments } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useListGalaxyMigrateDeployments } from "./deployment_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import Grid from "@mui/material/Grid2";

// ======================
// NewDeploymentButton
// ======================
interface NewDeploymentButtonProps {}

export const NewDeploymentButton: React.FC<NewDeploymentButtonProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    const apis = useGrpcApiStore();
    const newDeploymentOptionsDialogState = useDialogState();
    const [skipRelayOptions, setSkipRelayOptions] = useState(true);
    const projectId = useCurrentProjectID();
    const onDeploy = async () => {
        const req = new ListGalaxyMigrateDeployments.Request()
            .setProjectId(projectId)
            .setPageParams(new PagerParams().setPerPage(1))
            .setOnlyCdcRelayEnabled(true)
            .setOnlyConnected(false)
            .setExcludeHelperNodes(false);
        const res = await apis.deploymentService.listGalaxyMigrateDeployments(req, null);
        if (res?.getItemsList().length > 0) {
            setSkipRelayOptions(false);
        }

        newDeploymentOptionsDialogState.open();
    };

    return (
        <OperatorView>
            {newDeploymentOptionsDialogState.isOpen && (
                <NewDeploymentOptionsDialog dialogState={newDeploymentOptionsDialogState} skipRelayOptions={skipRelayOptions} />
            )}
            <Button color={"secondary"} variant={"contained"} onClick={onDeploy}>
                {`Deploy Cirrus Migrate Cloud`}
            </Button>
        </OperatorView>
    );
});

// ======================
// NewDeploymentOptionsDialog
// ======================

interface NewDeploymentOptionsDialogProps {
    dialogState: DialogState;
    skipRelayOptions?: boolean;
}

export const NewDeploymentOptionsDialog: React.FC<NewDeploymentOptionsDialogProps> = observer((p) => {
    const { dialogState } = p;
    const fullScreen = useShouldDialogFullScreen();
    const [showDirectDeploymentInstructions, setShowDirectDeploymentInstructions] = useState(p.skipRelayOptions);
    const [showRelayDeploymentSelection, setShowRelayDeploymentSelection] = useState(false);
    const [relayDeploymentSelection, setRelayDeploymentSelection] = useState(null as DeploymentInfo.AsObject);
    const helpDrawerOpen = useHelpDrawerState((s) => s.helpDrawerOpen);
    const helpDrawerWidth = useRightSideDrawerWidth();
    const isDesktop = useIsDesktop();
    const theme = useTheme();

    return (
        <Dialog
            open={dialogState.isOpen}
            maxWidth={"lg"}
            fullWidth
            fullScreen={fullScreen}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    dialogState.close();
                }
            }}
            sx={{
                "& .MuiDialog-container": {
                    ...(helpDrawerOpen && {
                        transition: theme.transitions.create(["margin", "width"], {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        width: isDesktop ? `calc(100% - ${helpDrawerWidth}px)` : "100%",
                    }),
                },
            }}
        >
            <DialogTopBar
                title={`Deploy Cirrus Migrate Cloud${!!relayDeploymentSelection ? ` via ${relayDeploymentSelection.systemName}` : ""}`}
                dialogState={dialogState}
                actions={[<HintButton hintID={relayDeploymentSelection ? AppHintID.CMC_RELAY : AppHintID.DEPLOY_CIRRUS_MIGRATE} />]}
            />
            {!showDirectDeploymentInstructions && !showRelayDeploymentSelection && (
                <NewDeploymentOptions
                    fullScreen={fullScreen}
                    showDirectInstructions={() => setShowDirectDeploymentInstructions(true)}
                    showRelayInstructions={() => setShowRelayDeploymentSelection(true)}
                />
            )}
            {showDirectDeploymentInstructions && <NewDeploymentInstructions />}
            {showRelayDeploymentSelection && !relayDeploymentSelection && (
                <NewRelayServerAddressSelection setRelayDeployment={(deployment: DeploymentInfo.AsObject) => setRelayDeploymentSelection(deployment)} />
            )}
            {!!relayDeploymentSelection && <NewDeploymentInstructions relayAddress={relayDeploymentSelection.cdcRelayServerAddress.split("/")[0]} />}
        </Dialog>
    );
});

// ======================
// NewDeploymentOptions
// ======================

interface NewDeploymentOptionsProps {
    fullScreen: boolean;
    showDirectInstructions: () => void;
    showRelayInstructions: () => void;
}

export const NewDeploymentOptions: React.FC<NewDeploymentOptionsProps> = observer((p) => {
    return (
        <DialogContent>
            <DialogContentText>Please select Connectivity to Cirrus Data Cloud</DialogContentText>
            <br />
            <Grid container spacing={3} height={p.fullScreen ? "auto" : "100%"}>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <SelectableCard
                        onSelect={() => {
                            p.showDirectInstructions();
                        }}
                        icon={<DirectIcon />}
                        cardProps={{
                            elevation: 0,
                            sx: {
                                height: p.fullScreen ? "auto" : "100%",
                                borderColor: "rgba(255, 255, 255, .1)",
                                border: "2px solid",
                            },
                        }}
                        selected={false}
                        title={"Direct Connect"}
                        description={
                            <span>
                                Connect from <b>Host</b> to <b>Cloud</b> directly (outgoing connection)
                            </span>
                        }
                    />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <SelectableCard
                        onSelect={() => {
                            p.showRelayInstructions();
                        }}
                        icon={<RelayIcon />}
                        cardProps={{
                            elevation: 0,
                            sx: {
                                height: p.fullScreen ? "auto" : "100%",
                                borderColor: "rgba(255, 255, 255, .1)",
                                border: "2px solid",
                            },
                        }}
                        selected={false}
                        title={"via Management Relay"}
                        description={
                            <span>
                                Connect from <b>Host</b> to <b>Cloud</b> via <b>another host with CDC Management Relay</b> enabled
                            </span>
                        }
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

// ======================
// NewRelayServerAddressSelection
// ======================

interface NewRelayServerAddressSelectionProps {
    setRelayDeployment: (deployment: DeploymentInfo.AsObject) => void;
}

export const NewRelayServerAddressSelection: React.FC<NewRelayServerAddressSelectionProps> = observer((p) => {
    const projectId = useCurrentProjectID();
    const relayDeployments = useListGalaxyMigrateDeployments(projectId, false, 1, 1000, false, [], true);

    return (
        <CardContent>
            <Typography>Please select a Management Relay</Typography>
            <br />
            <Card
                sx={{
                    backgroundColor: (t: Theme) => t.palette.cirrus.main,
                }}
            >
                <QueryResultWrapper queryResult={relayDeployments}>
                    {relayDeployments.data?.itemsList.map((d, i) => {
                        return (
                            <ListItemButton key={i} onClick={() => p.setRelayDeployment(d.deployment)}>
                                <ListItemText primary={d.deployment.systemName} secondary={d.deployment.cdcRelayServerAddress.split("/")[0]} />
                            </ListItemButton>
                        );
                    })}
                </QueryResultWrapper>
            </Card>
        </CardContent>
    );
});

const useNewDeploymentDialogStyles = () => {
    const t = useTheme();
    return {
        command: {
            background: t.palette.background.default,
        },
    };
};

// ======================
// NewDeploymentInstructions
// ======================

interface NewDeploymentInstructionsProps {
    relayAddress?: string;
}

export const NewDeploymentInstructions: React.FC<NewDeploymentInstructionsProps> = observer((p) => {
    const currentProject = useCurrentProject();
    const systemRegistrationCode = currentProject?.getInfo().getSystemRegistrationCode();
    const isPrivateEdition = getIsPrivateEdition();
    const t = useTheme();

    const tabConfigs: TabConfig[] = [
        {
            label: "Linux",
            icon: <DiLinux />,
            renderer: () => {
                return (
                    <DeploymentInstructions
                        os={"linux"}
                        relayAddress={p.relayAddress}
                        registrationCode={systemRegistrationCode}
                        privateEdition={isPrivateEdition}
                    />
                );
            },
        },
        {
            label: "Windows",
            icon: <DiWindows />,
            renderer: () => {
                return (
                    <DeploymentInstructions
                        os={"windows"}
                        relayAddress={p.relayAddress}
                        registrationCode={systemRegistrationCode}
                        privateEdition={isPrivateEdition}
                    />
                );
            },
        },
    ];
    return (
        <TabGroup
            configs={tabConfigs}
            border
            borderColor={t.palette.cirrus.main}
            tabsProps={{ variant: "fullWidth", indicatorColor: "primary", textColor: "primary" }}
        />
    );
});

// ======================
// DeploymentInstructions
// ======================

interface DeploymentInstructionsProps {
    registrationCode: string;
    relayAddress?: string;
    privateEdition?: boolean;
    os: "linux" | "windows";
}

export const DeploymentInstructions: React.FC<DeploymentInstructionsProps> = (p) => {
    const newInstallId = "newInstallDeploymentCommand";
    const uninstallId = "uninstallDeploymentCommand";

    const [command, setCommand] = useState<"install" | "uninstall">("install");

    const styles = useNewDeploymentDialogStyles();

    const [cdn, setCDN] = useState(true);
    const [prebuiltDrivers, setPrebuiltDrivers] = useState(true);
    const [skipSSLVerification, setSkipSSLVerification] = useState(false);
    const [allClusterNodes, setAllClusterNodes] = useState(false);

    const commandContent =
        command === "uninstall" ? (
            <>
                <Box p={2}>
                    <Box pb={2}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`Run the following Command to uninstall Cirrus Migrate Cloud after migration is completed:`}
                        </Typography>
                    </Box>
                    <Card sx={styles.command}>
                        <CardContent>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <ClipboardText clipboardId={uninstallId}>
                                    {generateDeploymentCommand(
                                        p.os,
                                        "uninstall",
                                        p.registrationCode,
                                        getApiHost(),
                                        false,
                                        p.relayAddress,
                                        p.privateEdition,
                                        cdn,
                                        allClusterNodes,
                                        prebuiltDrivers,
                                        skipSSLVerification
                                    )}
                                </ClipboardText>
                                <ClipboardButton clipboardId={uninstallId} iconButton />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Divider />
                <Box p={2}>
                    <Typography variant={"body1"} fontWeight={600}>
                        {"Options"}
                    </Typography>
                    <NewDeploymentChecklist
                        os={p.os}
                        cdnState={{ cdn, setCDN }}
                        skipSSLVerificationState={{ skipSSLVerification, setSkipSSLVerification }}
                        prebuiltDriversState={{ prebuiltDrivers, setPrebuiltDrivers }}
                        relayAddress={p.relayAddress}
                        clusterState={{ allClusterNodes, setAllClusterNodes }}
                    />
                </Box>
            </>
        ) : (
            <>
                <Box p={2}>
                    <Box pb={2}>
                        <Typography variant={"caption"} color={"textPrimary"}>
                            {p.os === "windows"
                                ? `In your Windows administrator account, run the following command in powershell to install Cirrus
                                Migrate Cloud:`
                                : `Run the following Command to install Cirrus Migrate Cloud:`}
                        </Typography>
                    </Box>
                    <Card sx={styles.command}>
                        <CardContent>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <ClipboardText clipboardId={newInstallId}>
                                    {generateDeploymentCommand(
                                        p.os,
                                        "install",
                                        p.registrationCode,
                                        getApiHost(),
                                        false,
                                        p.relayAddress,
                                        p.privateEdition,
                                        cdn,
                                        allClusterNodes,
                                        prebuiltDrivers,
                                        skipSSLVerification
                                    )}
                                </ClipboardText>
                                <ClipboardButton clipboardId={newInstallId} iconButton />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Divider />
                <Box p={2}>
                    <Typography variant={"body1"} fontWeight={600}>
                        {"Options"}
                    </Typography>
                    <NewDeploymentChecklist
                        os={p.os}
                        cdnState={{ cdn, setCDN }}
                        skipSSLVerificationState={{ skipSSLVerification, setSkipSSLVerification }}
                        prebuiltDriversState={{ prebuiltDrivers, setPrebuiltDrivers }}
                        clusterState={{ allClusterNodes, setAllClusterNodes }}
                        relayAddress={p.relayAddress}
                    />
                </Box>
            </>
        );

    return (
        <Box>
            <Grid container>
                <Grid
                    sx={{
                        borderRight: "1px solid black",
                    }}
                    size={2}
                >
                    <Stack p={2} spacing={2}>
                        <SelectableBox
                            selected={command === "install"}
                            onSelect={() => {
                                setCommand("install");
                            }}
                        >
                            <Box p={1}>
                                <Typography fontWeight={600} color={command === "install" ? "primary.main" : "textSecondary"}>
                                    {"Install"}
                                </Typography>
                            </Box>
                        </SelectableBox>
                        <SelectableBox
                            selected={command === "uninstall"}
                            onSelect={() => {
                                setCommand("uninstall");
                            }}
                        >
                            <Box p={1}>
                                <Typography fontWeight={600} color={command === "uninstall" ? "primary.main" : "textSecondary"}>
                                    {"Uninstall"}
                                </Typography>
                            </Box>
                        </SelectableBox>
                    </Stack>
                </Grid>
                <Grid size={10}>{commandContent}</Grid>
            </Grid>
        </Box>
    );
};

export const generateDeploymentCommand = (
    os: "linux" | "windows",
    command: "install" | "uninstall",
    registrationCode: string,
    hostString: string,
    cirrusProtect?: boolean,
    relayAddress?: string,
    privateEdition?: boolean,
    cdn?: boolean,
    allClusterNodes?: boolean,
    prebuiltDrivers?: boolean,
    skipSSLVerification?: boolean
): string => {
    const getAddressString = () => {
        if (!!relayAddress) {
            if (os === "linux") {
                return `https://${relayAddress}:${PortNumbers.CDC_RELAY}/install-cmc`;
            } else if (os === "windows") {
                return `http://${relayAddress}:${PortNumbers.CDC_RELAY_HTTP}/install-cmc-win`;
            }
        } else if (privateEdition) {
            const currentHost = window.location.host;
            if (os === "linux") {
                return `${currentHost}/sw/install-cmc`;
            } else if (os === "windows") {
                return `http://${currentHost}/sw/install-cmc-win`;
            }
        } else {
            const baseUrl = cdn ? `https://get.cirrusdata.cloud` : "https://installation-proxy.cloud.cirrusdata.com";
            if (os === "linux") {
                return `${baseUrl}/install-cmc`;
            } else if (os === "windows") {
                return `${baseUrl}/install-cmc-win`;
            }
        }
    };

    const windowsBaseCommand = `iex "& { $(irm ${getAddressString()}) }`;
    const linuxBaseCommand = `curl ${!!relayAddress || !!skipSSLVerification ? "-k" : ""} ${getAddressString()} | bash -s --`;

    const uninstall = " -uninstall";

    const cp = cirrusProtect ? " --cirrus-protect" : "";

    const portalHost = `${hostString}`.replace(/webapi/g, "portal");

    const gce = !!relayAddress || privateEdition ? "" : DEFAULT_PORTAL_HOST !== portalHost ? ` -gce ${portalHost}:443` : "";
    const rgc = ` -rgc ${registrationCode}`;

    const pkgMode = privateEdition ? "" : DEFAULT_PORTAL_HOST !== portalHost ? ` -pkg-mode PRE_RELEASE` : "";

    const noCdn = !relayAddress && !privateEdition && !cdn ? " -no-cdn" : "";

    const noPrebuiltDrivers = !prebuiltDrivers && !privateEdition ? " -no-prebuilt-mtdi-nexus" : "";

    const clusterNodes = allClusterNodes ? " -all-cluster-nodes" : "";

    const skipSsl = skipSSLVerification ? " -no-check-certificate" : "";

    if (os === "linux") {
        if (command === "install") {
            return `${linuxBaseCommand}${rgc}${gce}${cp}${pkgMode}${noCdn}${noPrebuiltDrivers}${skipSsl}`;
        } else {
            return `${linuxBaseCommand}${cp}${uninstall}${noCdn}${skipSsl}`;
        }
    } else if (os === "windows") {
        if (command === "install") {
            return `${windowsBaseCommand}${rgc}${gce}${cp}${pkgMode}${noCdn}${clusterNodes}${skipSsl}"`;
        } else {
            return `${windowsBaseCommand}${cp}${uninstall}${noCdn}${clusterNodes}${skipSsl}"`;
        }
    }
};

// ======================
// NewDeploymentOptions
// ======================

interface NewDeploymentChecklistProps {
    cdnState: { cdn: boolean; setCDN: (cdn: boolean) => void };
    clusterState?: { allClusterNodes: boolean; setAllClusterNodes: (allClusterNodes: boolean) => void };
    prebuiltDriversState?: { prebuiltDrivers: boolean; setPrebuiltDrivers: (prebuiltDrivers: boolean) => void };
    skipSSLVerificationState: {
        skipSSLVerification: boolean;
        setSkipSSLVerification: (skipSSLVerification: boolean) => void;
    };
    os: "linux" | "windows";
    relayAddress?: string;
    privateEdition?: boolean;
}

export const NewDeploymentChecklist: React.FC<NewDeploymentChecklistProps> = (p) => {
    const { cdnState, privateEdition, clusterState, prebuiltDriversState, skipSSLVerificationState, os, relayAddress } = p;
    return (
        <FormGroup>
            {!relayAddress && !privateEdition && (
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e, checked) => {
                                cdnState.setCDN(checked);
                            }}
                            value={cdnState.cdn}
                            defaultChecked
                        />
                    }
                    label={
                        <Box p={2}>
                            <Typography fontWeight={600}>{"Content Delivery Network (CDN)"}</Typography>
                            <Typography variant={"subtitle2"}>
                                {`Obtain software via Content Delivery Network (CDN) to maximize download performance. This option is recommended unless CDN access is restricted in your environment.`}
                            </Typography>
                        </Box>
                    }
                />
            )}
            {os === "windows" && (
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e, checked) => {
                                clusterState.setAllClusterNodes(checked);
                            }}
                            value={clusterState.allClusterNodes}
                        />
                    }
                    label={
                        <Box p={2}>
                            <Typography fontWeight={600}>{"All Cluster Nodes"}</Typography>
                            <Typography variant={"subtitle2"}>
                                {`Automatically applies the installation operation to all nodes within the cluster. This option is only applicable to supported cluster environments.`}
                            </Typography>
                        </Box>
                    }
                />
            )}
            {os === "linux" && !privateEdition && (
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e, checked) => {
                                prebuiltDriversState.setPrebuiltDrivers(checked);
                            }}
                            value={prebuiltDriversState.prebuiltDrivers}
                            defaultChecked
                        />
                    }
                    label={
                        <Box p={2}>
                            <Typography fontWeight={600}>{"Prebuilt Drivers"}</Typography>
                            <Typography variant={"subtitle2"}>
                                {`Download prebuilt linux drivers whenever possible. If not specified, linux drivers will always be built locally on your host.`}
                            </Typography>
                        </Box>
                    }
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={(e, checked) => {
                            skipSSLVerificationState.setSkipSSLVerification(checked);
                        }}
                        value={skipSSLVerificationState.skipSSLVerification}
                    />
                }
                label={
                    <Box p={2}>
                        <Typography fontWeight={600}>{"Skip SSL Verification"}</Typography>
                        <Typography variant={"subtitle2"}>
                            {`Disables the validation of SSL certificates during the installation process. Warning: This may expose the system to security risks in unsecured environments.`}
                        </Typography>
                    </Box>
                }
            />
        </FormGroup>
    );
};
