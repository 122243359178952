import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import * as React from "react";
import { useEffect } from "react";
import { ProjectDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { INTEGRATIONS_SUBROUTE, useQueryParams } from "../app/AppRoutes";
import { EnabledIntegrationsList } from "./EnabledIntegrationsList";
import { EnabledIntegrationsDetails } from "./EnabledIntegrationsDetails";
import { Box, Button, Link, List, ListItem, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import {
    AddIntegrationButton,
    getIntegrationCategoryDisplayName,
    getIntegrationModuleConfigsWithDefinitions,
    IntegrationCard,
    IntegrationCategory,
    IntegrationModuleConfig,
    IntegrationNonModuleId,
    IntegrationRedirectAlert,
    INTEGRATIONS_REDIRECTED_QUERY_PARAM,
} from "./IntegrationsCommon";
import { EditEnabledIntegration } from "./IntegrationForm/EditEnabledIntegration";
import { CreateNewIntegration } from "./IntegrationForm/CreateNewIntegration";
import { useIsFeatureEnabled } from "../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../app/AppGlobalService";
import { useIsDesktop } from "../layout/MainLayout";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";
import { AzureStoragePlannerWizard } from "./azureStoragePlanner/AzureStoragePlannerWizard";
import { AZURE_STORAGE_PLANNER_ROUTE, useIsAzureStoragePlannerEnabled } from "./azureStoragePlanner/AzureStoragePlannerHelpers";
import { AzureStoragePlanListView } from "./azureStoragePlanner/AzureStoragePlanList";
import { AzureStoragePlanDetailsPage } from "./azureStoragePlanner/AzureStoragePlanDetailsPage";
import { useListIntegrationModules } from "./integration_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { useIntegrationRedirectState } from "./IntegrationRedirectState";
import { Link as RouterLink } from "react-router-dom";
import { SelectableBox } from "../../common/card/SelectableCard";

// ======================
// ProjectIntegrationsScreen
// ======================

interface ProjectIntegrationsScreenProps {
    project: ProjectDetails;
}

export const ProjectIntegrationsScreens: React.FC<ProjectIntegrationsScreenProps> = observer((p) => {
    return (
        <Routes>
            <Route index element={<ProjectIntegrationsMainScreen />} />
            <Route path={INTEGRATIONS_SUBROUTE.CREATE}>
                <Route path={AZURE_STORAGE_PLANNER_ROUTE} element={<AzureStoragePlannerWizard />} />

                <Route path={`:integrationType`} element={<CreateNewIntegration />} />
            </Route>
            <Route path={INTEGRATIONS_SUBROUTE.ENABLED}>
                <Route index element={<EnabledIntegrationsList />} />
                <Route path={`:integrationId`}>
                    <Route index element={<EnabledIntegrationsDetails />} />
                    <Route path={"edit"} element={<EditEnabledIntegration />} />
                </Route>
            </Route>
            <Route path={INTEGRATIONS_SUBROUTE.AZURE_STORAGE_PLANNER}>
                <Route index element={<AzureStoragePlanListView />} />
                <Route path={":consumptionPlanId"} element={<AzureStoragePlanDetailsPage />} />
            </Route>
        </Routes>
    );
});

// ======================
// ProjectIntegrationsMainScreen
// ======================

interface ProjectIntegrationsMainScreenProps {}

export const ProjectIntegrationsMainScreen: React.FC<ProjectIntegrationsMainScreenProps> = observer((p) => {
    const query = useQueryParams();
    const { resetIntegrationRedirectState, preselectedHost, alertConfig } = useIntegrationRedirectState((s) => ({
        resetIntegrationRedirectState: s.resetIntegrationRedirectState,
        preselectedHost: s.preselectedHost,
        alertConfig: s.alertConfig,
    }));
    const isVMwareEnabled = useIsFeatureEnabled(FeatureFlag.COMPUTE_MIGRATION_VMWARE);
    const isStoragePlannerEnabled = useIsAzureStoragePlannerEnabled();
    const isSourceAssessmentEnabled = useIsFeatureEnabled(FeatureFlag.SOURCE_ASSESSMENT_REPORT);
    const integrationDefinitions = useListIntegrationModules();

    const integrationConfigs = getIntegrationModuleConfigsWithDefinitions(integrationDefinitions.data?.itemsList);

    const alertIntegrations = integrationConfigs.filter((i) => i.category === IntegrationCategory.ALERT);
    const publicCloudIntegrations = integrationConfigs.filter((i) => i.category === IntegrationCategory.PUBLIC_CLOUD);
    const iscsiStorageArrayIntegrations = integrationConfigs.filter((i) => i.category === IntegrationCategory.ISCSI_STORAGE_ARRAY);
    const fcStorageArrayIntegrations = integrationConfigs.filter((i) => i.category === IntegrationCategory.FC_STORAGE_ARRAY);
    const computeIntegrations = integrationConfigs.filter((i) => i.category === IntegrationCategory.COMPUTE_INTEGRATION);
    const softwareDefinedIntegrations = integrationConfigs.filter((i) => i.category === IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE);
    const containerIntegrations = integrationConfigs.filter((i) => i.category === IntegrationCategory.CONTAINERS);
    const getStoragePlannerIntegrations = () => {
        const integrations = [];
        if (isStoragePlannerEnabled) {
            integrations.push(integrationConfigs.find((c) => c.nonModuleId === IntegrationNonModuleId.AZURE_STORAGE_PLANNER));
        }
        if (isSourceAssessmentEnabled) {
            integrations.push(integrationConfigs.find((c) => c.nonModuleId === IntegrationNonModuleId.SOURCE_DATA_ASSESSMENT));
        }
        return integrations;
    };

    useEffect(() => {
        if (!query.get(INTEGRATIONS_REDIRECTED_QUERY_PARAM)) {
            resetIntegrationRedirectState();
        }
    }, [query.get(INTEGRATIONS_REDIRECTED_QUERY_PARAM), resetIntegrationRedirectState]);

    return (
        <ScreenContainer>
            {!!preselectedHost && <IntegrationRedirectAlert boxProps={{ pb: 2 }} alertConfig={alertConfig} />}
            <ScreenTitleBar title={"Integration Goodies"} />
            <Grid container spacing={1} mb={2}>
                {Object.keys(IntegrationCategory)
                    .filter((key) => isNaN(Number(key)))
                    .map((category, index) => {
                        const title = getIntegrationCategoryDisplayName(IntegrationCategory[category as keyof typeof IntegrationCategory]);
                        return (
                            <Grid key={category}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Link href={`#${title}`}>{title}</Link>
                                    {index < Object.keys(IntegrationCategory).filter((key) => isNaN(Number(key))).length - 1 && <Typography>{`|`}</Typography>}
                                </Stack>
                            </Grid>
                        );
                    })}
            </Grid>

            <QueryResultWrapper queryResult={integrationDefinitions}>
                <IntegrationsGoodiesSection title={getIntegrationCategoryDisplayName(IntegrationCategory.ALERT)} integrationsList={alertIntegrations} />
                <IntegrationsGoodiesSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.PUBLIC_CLOUD)}
                    integrationsList={publicCloudIntegrations}
                />
                <IntegrationsGoodiesSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.ISCSI_STORAGE_ARRAY)}
                    integrationsList={iscsiStorageArrayIntegrations}
                />
                <IntegrationsGoodiesSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.FC_STORAGE_ARRAY)}
                    integrationsList={fcStorageArrayIntegrations}
                />
                <IntegrationsGoodiesSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE)}
                    integrationsList={softwareDefinedIntegrations}
                />
                {
                    // isVMwareEnabled &&
                    <IntegrationsGoodiesSection
                        title={getIntegrationCategoryDisplayName(IntegrationCategory.COMPUTE_INTEGRATION)}
                        integrationsList={computeIntegrations}
                    />
                }
                {isStoragePlannerEnabled && (
                    <IntegrationsGoodiesSection
                        title={getIntegrationCategoryDisplayName(IntegrationCategory.PUBLIC_CLOUD_STORAGE_PLANNER)}
                        integrationsList={getStoragePlannerIntegrations()}
                    />
                )}
                <IntegrationsGoodiesSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.CONTAINERS)}
                    integrationsList={containerIntegrations}
                />
            </QueryResultWrapper>
        </ScreenContainer>
    );
});

// ======================
// IntegrationsGoodiesSection
// ======================

interface IntegrationsGoodiesSectionProps {
    title: string;
    integrationsList: IntegrationModuleConfig[];
}

export const IntegrationsGoodiesSection: React.FC<IntegrationsGoodiesSectionProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const numOfIntegrations = p.integrationsList.length;
    const openHelpArticle = useOpenHelpArticle();

    if (numOfIntegrations > 0) {
        return (
            <Box pb={3} id={p.title}>
                <Typography variant={"h6"}>{`${p.title} (${numOfIntegrations})`}</Typography>
                <br />
                <Grid container spacing={2}>
                    {p.integrationsList.map((i, index) => {
                        const getMainActionButton = () => {
                            if (!!i.route) {
                                return (
                                    <AddIntegrationButton
                                        label={i.mainActionButtonLabel}
                                        route={i.route}
                                        disabled={i.disabled}
                                        defaultEnabled={i.defaultEnabled}
                                    />
                                );
                            } else if (!!i.externalLink) {
                                return (
                                    <Button variant={"contained"} color={"primary"} onClick={() => openHelpArticle(i.externalLink as KnownArticle)}>
                                        {i.mainActionButtonLabel}
                                    </Button>
                                );
                            } else if (!!i.mainActionButton) {
                                return i.mainActionButton;
                            }
                        };
                        return (
                            <Grid key={index} size={isDesktop ? false : 12}>
                                <IntegrationCard
                                    module={i}
                                    mainActionButton={getMainActionButton()}
                                    cardProps={{ sx: { width: isDesktop ? 325 : "100%", height: "100%" } }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }
    return null;
});
