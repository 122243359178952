import { LicenseModel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { GalaxyMigrateStorageConfig } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { HostLicenseInfo, VaultDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { HostEnvironment } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";
import { useNavigate, useParams } from "react-router-dom";
import xbytes from "xbytes";
import { CMC_MIGRATION_SUBROUTE } from "../../app/AppRoutes";
import { useAppServices } from "../../app/services";
import { DEFAULT_LICENSE_CAPACITY, getIsHostLicenseExpired } from "../../galaxymigrate/hostLicense/HostLicenseCommon";
import { generateMigrationSubPathRoute } from "../CmcMigrationCommon";
import { GmMigrationType, GmMigrationWizardState, GmMigrationWizardStep, GmMigrationWizardVolumeState } from "../GmMigrationService";

export const renderWizardStepText = (step: GmMigrationWizardStep, wizardState: GmMigrationWizardState): string => {
    switch (step) {
        case GmMigrationWizardStep.MIGRATION_PARAMETERS:
            return `Migration Parameters`;
        case GmMigrationWizardStep.VOLUMES_SELECTION:
            return `Volume Selections`;
        case GmMigrationWizardStep.SOURCE_DEPLOYMENT_SELECTION:
            return `Source Host ` + (wizardState.selectedDeploymentName ? ` - ${wizardState.selectedDeploymentName}` : "");
        case GmMigrationWizardStep.MIGRATION_TYPE:
            return `Migration Type` + (wizardState.selectedMigrationType ? ` - ${renderMigrationTypeText(wizardState.selectedMigrationType)}` : "");
        case GmMigrationWizardStep.COMPUTE_VMWARE_VM_CONFIG:
            return `New VM Specification`;
        case GmMigrationWizardStep.COMPUTE_MIGRATION_SOURCE_PREP:
            return `Source Preparation`;
    }
    return null;
};

export const renderMigrationTypeText = (type: GmMigrationType) => {
    if (type === GmMigrationType.COMPUTE_VMWARE) {
        return "VMWare";
    } else if (type === GmMigrationType.LOCAL) {
        return "Local";
    } else if (type === GmMigrationType.REMOTE) {
        return "Remote";
    } else if (type === GmMigrationType.MOCK_MIGRATION) {
        return "Mock";
    } else {
        return "";
    }
};

export const getHostLicenseInsufficientForMigration = (hostLicense: HostLicenseInfo, projectHasAvailableHostLicense: boolean) => {
    return !hostLicense && !projectHasAvailableHostLicense;
};

export const getHostWillConsumeProjectLicense = (hostLicense: HostLicenseInfo, projectHasAvailableHostLicense: boolean) => {
    return !hostLicense && projectHasAvailableHostLicense;
};

export const getHostLicenseExpiredNoExtension = (hostLicense: HostLicenseInfo, projectHasAvailableExtension: boolean) => {
    return !!hostLicense && getIsHostLicenseExpired(hostLicense.getExpireAt().toDate()) && !projectHasAvailableExtension;
};

export const getHostLicenseExpiredHasExtension = (hostLicense: HostLicenseInfo, projectHasAvailableExtension: boolean) => {
    return !!hostLicense && getIsHostLicenseExpired(hostLicense.getExpireAt().toDate()) && projectHasAvailableExtension;
};

export const getDeploymentSelectionContinueDisabled = (
    licenseModel: LicenseModel.LicenseModel,
    wizardState: GmMigrationWizardState,
    licenseInsufficient: boolean,
    licenseExpired: boolean
) => {
    if (licenseModel === LicenseModel.LicenseModel.HOST_BASED) {
        return !wizardState.deploymentId || licenseInsufficient || licenseExpired;
    } else {
        return !wizardState.deploymentId;
    }
};

export const getMigrationTypeSelectionContinueDisabled = (wizardState: GmMigrationWizardState) => {
    const volumeTypeSelected = wizardState.selectedMigrationVolumeType !== null;
    if (wizardState.selectedMigrationType !== null && volumeTypeSelected) {
        if (wizardState.selectedMigrationType === GmMigrationType.REMOTE || wizardState.isComputeMigration) {
            return !wizardState.selectedRemoteDestinationId;
        } else {
            return false;
        }
    }
    return true;
};

export const getVolumesSelectionContinueDisabled = (
    licenseModel: LicenseModel.LicenseModel,
    wizardState: GmMigrationWizardState,
    totalSelectedVolumeCapacity: number,
    projectLicense: VaultDetails.AsObject,
    shareableCapacity: number
) => {
    if (wizardState.migrationType === GmMigrationType.MOCK_MIGRATION) {
        return !wizardState.hasSelectedVolumes;
    }
    const hostLicense = wizardState.deployment.data?.getInfo().getDeployment().getLicense();
    if (licenseModel === LicenseModel.LicenseModel.HOST_BASED) {
        if (!wizardState.validVolumeSelections) {
            return true;
        } else {
            return (
                getSelectedVolumeCapacityExceedsHostBalance(totalSelectedVolumeCapacity, hostLicense) &&
                getSelectedVolumeCapacityExceedsShareableBalance(totalSelectedVolumeCapacity, hostLicense, shareableCapacity) &&
                !getNumOfExtensionsToBeConsumedByMigrationSession(totalSelectedVolumeCapacity, hostLicense, projectLicense)
            );
        }
    } else {
        return !wizardState.validVolumeSelections;
    }
};

export const getVolumeSelectionDisabled = (wizardState: GmMigrationWizardState, vol: GalaxyMigrateStorageConfig.Device) => {
    if (wizardState.selectedMigrationType === GmMigrationType.REMOTE) {
        return false;
    } else if (wizardState.isComputeMigration) {
        return vol.getBlockDevice().getBoot();
    } else {
        return !!Object.values(wizardState.selectedVolumes).find(
            (v) => v.destination?.getBlockDevice().getDeviceName() === vol.getBlockDevice().getDeviceName()
        );
    }
};

export const getTotalSelectedVolumeCapacity = (selectedVolumes: { [key: string]: GmMigrationWizardVolumeState }) => {
    if (Object.values(selectedVolumes).length > 0) {
        return Object.values(selectedVolumes)
            .map((v) => v.source.getBlockDevice().getCapacity())
            .reduce((p, c) => p + c);
    }
    return 0;
};

export const getSelectedVolumeCapacityExceedsHostBalance = (selectedVolumeCapacity: number, hostLicense?: HostLicenseInfo) => {
    const remainingCapacity = !!hostLicense ? hostLicense?.getMigrationCapacityRemaining() : DEFAULT_LICENSE_CAPACITY;
    return selectedVolumeCapacity > remainingCapacity;
};

export const getSelectedVolumeCapacityExceedsShareableBalance = (selectedVolumeCapacity: number, hostLicense: HostLicenseInfo, shareableCapacity: number) => {
    const remainingCapacity = !!hostLicense ? hostLicense?.getMigrationCapacityRemaining() : DEFAULT_LICENSE_CAPACITY;
    return selectedVolumeCapacity > remainingCapacity + shareableCapacity;
};

export const getSelectedVolumeCapacityExceedsProjectBalance = (selectedVolumeCapacity: number, projectBalance: VaultDetails.AsObject) => {
    return (
        selectedVolumeCapacity >
            projectBalance?.itemsList.find((c) => c.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION)
                ?.currentBalance || 0
    );
};

export const getNumOfExtensionsToBeConsumedByMigrationSession = (
    selectedVolumeCapacity: number,
    hostLicense: HostLicenseInfo,
    projectLicense: VaultDetails.AsObject
) => {
    if (!getSelectedVolumeCapacityExceedsHostBalance(selectedVolumeCapacity, hostLicense)) {
        return 0;
    }

    const hostLicenseBalance = !!hostLicense ? hostLicense?.getMigrationCapacityRemaining() : DEFAULT_LICENSE_CAPACITY;
    const projectAvailableExtensionLicenses = projectLicense.itemsList.find(
        (c) => c.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION
    )?.currentBalance;
    if (projectAvailableExtensionLicenses > 0) {
        for (let i = 1; i <= projectAvailableExtensionLicenses; i++) {
            const addedBalance = hostLicenseBalance + xbytes.parseSize(`${i} TiB`);
            if (addedBalance >= selectedVolumeCapacity) {
                return i;
            }
        }
    }
    return 0;
};

export const useNavigateToAutoAllocation = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateMigrationSubPathRoute(projectId, CMC_MIGRATION_SUBROUTE.AUTO_ALLOCATION);
    return () => navigate(p);
};

export const useInitiateAutoAllocation = (wizardState: GmMigrationWizardState) => {
    const { gmMigrationService } = useAppServices();
    const navigateToAutoAllocation = useNavigateToAutoAllocation();
    //const enabledIntegration = useGetEnabledIntegrationDetails(wizardState.azureConfigState?.selectedAzureHelper?.integrationId);

    return async () => {
        const selectedSourceVolumes = wizardState.selectedVolumes;
        if (!!wizardState.selectedRemoteDestinationId) {
            gmMigrationService.initAutoAllocationWizard(
                wizardState.remoteDeployment?.data,
                Object.values(selectedSourceVolumes),
                wizardState.allowSmallerDestinations
            );
        } else {
            gmMigrationService.initAutoAllocationWizard(
                wizardState.deployment?.data,
                Object.values(selectedSourceVolumes),
                wizardState.allowSmallerDestinations
            );
        }

        // if (!!wizardState.azureConfigState) {
        //     gmMigrationService.autoAllocationState.selectIntegration(enabledIntegration.data?.getInfo());
        //     gmMigrationService.autoAllocationState.selectCurrentStep(GmAutoAllocationStep.PREPARE_HOST);
        // }

        navigateToAutoAllocation();
    };
};

export const getIsComputeMigration = (migrationType: GmMigrationType) => {
    const computeMigrationTypes = [GmMigrationType.COMPUTE_VMWARE];

    return computeMigrationTypes.includes(migrationType);
};

export const getHostEnvironmentFromMigrationType = (migrationType: GmMigrationType) => {
    // if (migrationType === GmMigrationType.COMPUTE_AZURE) {
    //     return HostEnvironment.AZURE;
    if (migrationType === GmMigrationType.COMPUTE_VMWARE) {
        return HostEnvironment.VMWARE;
    }
};
