import { observer } from "mobx-react-lite";
import React from "react";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { IntegrationConfigInfo, IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import {
    getIntegrationCategoryDisplayName,
    getIntegrationModuleConfigs,
    getModuleConfigByModule,
    IntegrationCard,
    IntegrationCategory,
    useNavigateToEditIntegrationForm,
    useNavigateToEnabledIntegrations,
} from "./IntegrationsCommon";
import { ActionConfig } from "../../common/actions/CommonActions";
import { DeleteIcon, EditIcon } from "../../common/CommonIcons";
import { IntegrationsIcon } from "../project/ProjectCommon";
import { useDialogState } from "../core/dialog/DialogService";
import { TestIntegrationDialog } from "./EnabledIntegrationsDetails";
import { GalaxyMigrateAutoAllocationProgressDialog } from "../cmcMigration/autoallocation/GmAutoAllocationProgress";
import { Box, Typography } from "@mui/material";
import { IntegrationsGoodiesSection } from "./ProjectIntegrationsScreens";
import { useIsOperatorView } from "../auth/AuthenticatedViews";
import { useIsDesktop } from "../layout/MainLayout";
import { useListIntegrationModules, useListProjectIntegrations, useRemoveProjectIntegration } from "./integration_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import Grid from "@mui/material/Grid2";

// ======================
// EnabledIntegrations
// ======================

interface EnabledIntegrationsProps {}

export const EnabledIntegrationsList: React.FC<EnabledIntegrationsProps> = observer((p) => {
    const goBackToEnabledIntegrations = useNavigateToEnabledIntegrations();
    const goToEditForm = useNavigateToEditIntegrationForm();

    const testIntegrationDialogState = useDialogState();

    const isOperator = useIsOperatorView();

    const integrationsList = useListProjectIntegrations();
    const removeProjectIntegration = useRemoveProjectIntegration();
    const globalDialogState = useGlobalDialogState();

    const cardActionsGetter = (i: IntegrationConfigInfo.AsObject): ActionConfig[] => [
        {
            id: "edit",
            name: "Edit Integration",
            icon: <EditIcon />,
            action: async () => {
                goToEditForm(i.id.toString());
            },
            hidden: !isOperator,
        },
        {
            id: "test",
            name: "Test Integration",
            action: () => {
                testIntegrationDialogState.setDialogProps({ id: i.id });
                testIntegrationDialogState.open();
            },
            icon: <IntegrationsIcon />,
            hidden: !isOperator,
        },
        {
            id: "remove",
            name: "Remove Integration",
            icon: <DeleteIcon />,
            action: async () => {
                const confirmed = await globalDialogState.addConfirmDialog({
                    message: "Are you sure you want to remove this integration?",
                    autoConfirmationQuestionLine: false,
                });
                if (confirmed) {
                    await removeProjectIntegration.mutateAsync(i.id);
                    goBackToEnabledIntegrations();
                }
            },
            hidden: !isOperator,
        },
    ];

    const publicCloudIntList = getIntegrationModuleConfigs()
        .filter((i) => i.category === IntegrationCategory.PUBLIC_CLOUD)
        .map((i) => i.module);

    const iscsiIntList = getIntegrationModuleConfigs()
        .filter((i) => i.category === IntegrationCategory.ISCSI_STORAGE_ARRAY)
        .map((i) => i.module);
    const fcIntList = getIntegrationModuleConfigs()
        .filter((i) => i.category === IntegrationCategory.FC_STORAGE_ARRAY)
        .map((i) => i.module);
    const hypervisorsIntList = getIntegrationModuleConfigs()
        .filter((i) => i.category === IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE)
        .map((i) => i.module);
    const containerIntegrationsList = getIntegrationModuleConfigs()
        .filter((i) => i.category === IntegrationCategory.CONTAINERS)
        .map((i) => i.module);

    const alertIntegrations = getIntegrationModuleConfigs().filter((i) => i.category === IntegrationCategory.ALERT);
    const publicCloudIntegrations = integrationsList.data?.itemsList.filter((i) => publicCloudIntList.includes(i.module));
    const iscsiStorageArrayIntegrations = integrationsList.data?.itemsList.filter((i) => iscsiIntList.includes(i.module));
    const fcStorageArrayIntegrations = integrationsList.data?.itemsList.filter((i) => fcIntList.includes(i.module));
    const hypervisorsIntegrations = integrationsList.data?.itemsList.filter((i) => hypervisorsIntList.includes(i.module));
    const containerIntegrations = integrationsList.data?.itemsList.filter((i) => containerIntegrationsList.includes(i.module));

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Enabled Integrations"} />
            <QueryResultWrapper queryResult={integrationsList}>
                <IntegrationsGoodiesSection title={getIntegrationCategoryDisplayName(IntegrationCategory.ALERT)} integrationsList={alertIntegrations} />
                <EnabledIntegrationsSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.PUBLIC_CLOUD)}
                    integrationsList={publicCloudIntegrations}
                    actionsGetter={cardActionsGetter}
                />
                <EnabledIntegrationsSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.ISCSI_STORAGE_ARRAY)}
                    integrationsList={iscsiStorageArrayIntegrations}
                    actionsGetter={cardActionsGetter}
                />
                <EnabledIntegrationsSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.FC_STORAGE_ARRAY)}
                    integrationsList={fcStorageArrayIntegrations}
                    actionsGetter={cardActionsGetter}
                />
                <EnabledIntegrationsSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE)}
                    integrationsList={hypervisorsIntegrations}
                    actionsGetter={cardActionsGetter}
                />
                <EnabledIntegrationsSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.CONTAINERS)}
                    integrationsList={containerIntegrations}
                    actionsGetter={cardActionsGetter}
                />
            </QueryResultWrapper>
            <TestIntegrationDialog dialogState={testIntegrationDialogState} />
            <GalaxyMigrateAutoAllocationProgressDialog actionLabel={"Close"} />
        </ScreenContainer>
    );
});

// ======================
// IntegrationsSection
// ======================

interface EnabledIntegrationsSectionProps {
    title: string;
    integrationsList: IntegrationConfigInfo.AsObject[];
    actionsGetter?: (i: IntegrationConfigInfo.AsObject) => ActionConfig[];
}

const EnabledIntegrationsSection: React.FC<EnabledIntegrationsSectionProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const numOfIntegrations = p.integrationsList.length;
    const integrationDefs = useListIntegrationModules();

    if (numOfIntegrations > 0) {
        return (
            <QueryResultWrapper queryResult={integrationDefs}>
                <Box pb={3}>
                    <>
                        <Typography variant={"h6"}>{`${p.title} (${numOfIntegrations})`}</Typography>
                        <br />
                    </>
                    <Grid container spacing={2}>
                        {p.integrationsList.map((i, index) => {
                            return (
                                <Grid key={index} size={isDesktop ? false : 12}>
                                    <IntegrationCard
                                        cardActionsGetter={p.actionsGetter}
                                        module={getModuleConfigByModule(i.module, integrationDefs.data?.itemsList)}
                                        instance={i}
                                        cardProps={{ sx: { width: isDesktop ? 325 : "100%" } }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </QueryResultWrapper>
        );
    }
    return null;
});
