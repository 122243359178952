// Project: GalaxyComplete
// Created: 11/11/20 by sammy
// File: GalaxyMigrateDeploymentSettings

import * as React from "react";
import { observer } from "mobx-react-lite";
import { OperatorView } from "../../auth/AuthenticatedViews";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogTitle,
    DialogContent,
    InputAdornment,
    ListSubheader,
    Stack,
    SvgIcon,
    TextField,
    Theme,
    Typography,
    DialogActions,
} from "@mui/material";
import { GmDeploymentOnlineUpgradeSettingArea } from "./GalaxyMigrateDeploymentOnlineUpgrade";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { MdVpnKey } from "react-icons/md";
import { useAppServices } from "../../app/services";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { useNavigateToProjectLicenseDetailsScreen } from "../../management/ProjectManagementCommon";
import { useIsFeatureEnabled } from "../../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../../app/AppGlobalService";
import { BsSpeedometer } from "react-icons/bs";
import { useGetDeploymentMaxThroughput, useSetDeploymentMaxThroughput } from "../gm_deployment_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { isDeploymentGteVersion, isMinMtdiVersion, renderIfConnectedDeployment } from "../../deployment/DeploymentCommon";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { KnownArticle, LearnMoreLink } from "../../help/HelpCommon";

// ======================
// GmDeploymentDetailsSettings
// ======================

interface GmDeploymentDetailsSettingsProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GmDeploymentDetailsSettings: React.FC<GmDeploymentDetailsSettingsProps> = observer((props) => {
    const { deployment } = props;
    const isFeatureEnabled = useIsFeatureEnabled(FeatureFlag.CMC_SOFTWARE_UPGRADE);

    return (
        <OperatorView>
            <Box pb={6}>
                <Card>
                    <ListSubheader>{"Settings"}</ListSubheader>
                    {deployment.getInfo().getDeployment().getConnected() && isFeatureEnabled && <GmDeploymentOnlineUpgradeSettingArea />}
                    <HostLicenseArea deployment={deployment} />
                    <HostMaxThroughputArea deployment={deployment} />
                </Card>
            </Box>
        </OperatorView>
    );
});

// ======================
// HostLicenseArea
// ======================

interface HostLicenseAreaProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const HostLicenseArea: React.FC<HostLicenseAreaProps> = observer((p) => {
    const { dialogService, gmDeploymentService, deploymentService, licenseService } = useAppServices();

    const goToLicenseDetails = useNavigateToProjectLicenseDetailsScreen();
    const getButtonDisplay = () => {
        if (p.deployment.getInfo().getDeployment().getLicense()?.hasActivatedAt()) {
            return (
                <Button
                    variant={"outlined"}
                    color={"success"}
                    disableRipple
                    sx={{
                        cursor: "default",
                        borderColor: (t: Theme) => t.palette.success.main,
                        "&:hover": {
                            backgroundColor: "rgba(0,0,0,0)",
                        },
                    }}
                >
                    License Activated
                </Button>
            );
        }

        const handleActivate = async () => {
            await licenseService.projectLicenseDetails.fetchData();
            const currentBalance = licenseService.projectLicenseDetails.data?.itemsList.find(
                (v) => v.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.HOST_UNLIMITED_MIGRATION
            )?.currentBalance;
            if (currentBalance > 0) {
                const confirmed = await dialogService.addConfirmDialog({
                    renderAdditionalContent: () => (
                        <Box>
                            <Typography>Host Migration License Remaining: {currentBalance}</Typography>
                            <Typography sx={{ color: (t: Theme) => t.palette.warning.dark }}>Note: this will be deducted from project license key</Typography>
                            <br />
                            <Typography>Are you sure you want to activate Host Migration License?</Typography>
                        </Box>
                    ),
                    autoConfirmationQuestionLine: false,
                    okButtonLabel: "Confirm License Activation",
                });
                if (confirmed) {
                    await gmDeploymentService.assignUnlimitedLicense(p.deployment.getInfo().getDeployment().getSystemId());
                    await deploymentService.currentDeployment.fetchData();
                }
            } else {
                await dialogService.addAlertDialog({
                    title: "License cannot be activated",
                    message: "No remaining host migration licenses available.",
                    renderAdditionalContent: () => (
                        <Box display={"flex"} justifyContent={"center"} pt={4}>
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    goToLicenseDetails();
                                    dialogService.clearAlertDialogs();
                                }}
                            >
                                view project license details
                            </Button>
                        </Box>
                    ),
                    okButtonLabel: "close",
                });
            }
        };

        return (
            <Button variant={"outlined"} onClick={handleActivate}>
                Activate License
            </Button>
        );
    };

    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader
                    avatar={
                        <SvgIcon>
                            <MdVpnKey />
                        </SvgIcon>
                    }
                    title={"Activate Host Migrate License"}
                    subheader={"Host Migration License will allow unlimited migration for this host when activated"}
                    action={getButtonDisplay()}
                />
            </Card>
        </Box>
    );
});

interface HostMaxThroughputAreaProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

const HostMaxThroughputArea: React.FC<HostMaxThroughputAreaProps> = (p: HostMaxThroughputAreaProps) => {
    const { deployment } = p;
    const dialogState = useDialogState();

    const getIsVersionSupported = () => {
        return isDeploymentGteVersion(deployment, "8.2.0");
    };

    const setMaxThroughputButton = (
        <Button variant={"outlined"} onClick={dialogState.open}>
            Configure
        </Button>
    );

    return getIsVersionSupported() ? (
        <>
            {renderIfConnectedDeployment(
                deployment,
                <Box p={2} pt={0}>
                    <Card variant={"outlined"}>
                        <CardHeader
                            avatar={
                                <SvgIcon>
                                    <BsSpeedometer />
                                </SvgIcon>
                            }
                            title={"Configure Maximum Migration Bandwidth"}
                            subheader={`Migrations on this host will throttled up to the configured aggregated bandwidth limit (all sessions combined).`}
                            action={
                                <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                    {setMaxThroughputButton}
                                </Stack>
                            }
                        />
                    </Card>
                </Box>
            )}
            {dialogState.isOpen && <MaxThroughputDialog deployment={deployment} dialogState={dialogState} />}
        </>
    ) : null;
};

interface MaxThroughputDialogProps {
    deployment: GalaxyMigrateDeploymentDetails;
    dialogState: DialogState;
}

const MaxThroughputDialog: React.FC<MaxThroughputDialogProps> = (p: MaxThroughputDialogProps) => {
    const { deployment, dialogState } = p;
    const globalDialogState = useGlobalDialogState();
    const maxThroughput = useGetDeploymentMaxThroughput(deployment.getInfo().getDeployment().getSystemId());
    const setMaxThroughput = useSetDeploymentMaxThroughput();
    const [newMaxThroughput, setNewMaxThroughput] = React.useState<string | undefined>(null);
    const [newMaxThroughputError, setNewMaxThroughputError] = React.useState<string | undefined>(null);
    const fullScreen = useShouldDialogFullScreen();

    const handleSetMaxThroughput = async () => {
        if (newMaxThroughput) {
            const confirmed = await globalDialogState.addConfirmDialog({
                title: "Confirm Maximum Migration Bandwidth Change",
                message: "Are you sure you want to change the migration bandwidth for this host?",
                autoConfirmationQuestionLine: false,
                okButtonLabel: "Confirm",
                cancelButtonLabel: "Cancel",
            });
            if (confirmed) {
                await setMaxThroughput.mutateAsync({
                    systemId: deployment.getInfo().getDeployment().getSystemId(),
                    throughput: Number(newMaxThroughput) * (1024 * 1024),
                });
                await maxThroughput.refetch();
                setNewMaxThroughput("");
                dialogState.close();
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" fullScreen={fullScreen} open={dialogState.isOpen} onClose={dialogState.close}>
            <DialogTitle>Configure Maximum Migration Bandwidth</DialogTitle>
            <DialogContent>
                <QueryResultWrapper queryResult={maxThroughput}>
                    <Stack direction="column" spacing={2} pb={2}>
                        <Typography>
                            {maxThroughput.data?.maxThroughput
                                ? `The maximum migration bandwidth for this host is currently ${formatKnownDataType(
                                      maxThroughput.data?.maxThroughput,
                                      KnownDataType.THROUGHPUT
                                  )}.`
                                : `Host-wide migration throttling is not enabled.`}
                        </Typography>

                        <TextField
                            fullWidth
                            label="New Bandwidth"
                            variant="filled"
                            type="number"
                            size="small"
                            error={!!newMaxThroughputError}
                            helperText={newMaxThroughputError || "Set to 0 to disable host-wide migration throttling."}
                            value={newMaxThroughput}
                            onChange={(e) => {
                                setNewMaxThroughput(e.target.value);
                                if (isNaN(Number(e.target.value))) {
                                    setNewMaxThroughputError("Please enter a valid number");
                                } else {
                                    setNewMaxThroughputError(null);
                                }
                                if (Number(e.target.value) < 0) {
                                    setNewMaxThroughputError("Please enter a positive number");
                                } else {
                                    setNewMaxThroughputError(null);
                                }
                            }}
                            slotProps={{
                                input: {
                                    endAdornment: <InputAdornment position="end">MiB/s</InputAdornment>,
                                },
                            }}
                        />
                        <Typography variant="body2" color={"textSecondary"}>
                            <LearnMoreLink articleID={KnownArticle.MIGRATION_THROTTLING} /> about migration throttling.
                        </Typography>
                    </Stack>
                </QueryResultWrapper>
            </DialogContent>
            <DialogActions>
                <Button disableElevation variant={"outlined"} onClick={dialogState.close} color={"neutral"}>
                    Cancel
                </Button>
                <Button disableElevation disabled={!newMaxThroughput} variant={"contained"} onClick={handleSetMaxThroughput}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
