export const AuthQueryKeys = {};

export const AuthMutationKeys = {
    signUp: "SignUp",
    requestMfaToken: "RequestMfaToken",
    logIn: "LogIn",
    logOut: "LogOut",
    requestResetPassword: "RequestResetPassword",
    setNewPassword: "SetNewPassword",
    changeMyPassword: "ChangeMyPassword",
    activateUser: "ActivateUser",
    disableMfaTotp: "DisableMfaTotp",
};

export const UserQueryKeys = {
    listMyUserSessions: "ListMyUserSessions",
    getMyUserInfo: "GetMyUserInfo",
    generateApiToken: "GenerateApiToken",
};

export const UserMutationKeys = {
    revokeUserSession: "RevokeUserSession",
    fetchNewAvatarLink: "FetchNewAvatarLink",
    replaceAvatar: "ReplaceAvatar",
    removeAvatar: "RemoveAvatar",
    uploadAvatar: "UploadAvatar",
    updateAvatar: "UpdateAvatar",
    updateCurrentUser: "UpdateCurrentUser",
    generateApiToken: "GenerateApiToken",
    revokeApiToken: "RevokeApiToken",
    updateMyUserInfo: "UpdateMyUserInfo",
};

export const MigrationQueryKeys = {
    getComputeMigrationSourceReadiness: "GetComputeMigrationSourceReadiness",
    ListAzureHelpers: "ListAzureHelpers",
    GetAzureHelperResourceSelections: "GetAzureHelperResourceSelections",
    GetVmwareHelperResourceSelections: "GetVmwareHelperResourceSelections",
};

export const CmcMigrationMutationKeys = {
    syncMigrationSession: "SyncMigrationSession",
};

export const ReportQueryKeys = {
    getReportData: "GetReportData",
    listProjectReports: "ListProjectReports",
};

export const ReportMutationKeys = {
    createMigrationSessionReport: "CreateMigrationSessionReport",
    createProjectLicenseReport: "CreateProjectLicenseReport",
    createHostConfigurationReport: "CreateHostConfigurationReport",
    createMigrationSourceDataAssessmentReport: "CreateMigrationSourceDataAssessmentReport",
};

export const DeploymentQueryKeys = {
    listGmDeployments: "ListGmDeployments",
    listGmLinks: "ListGmLinks",
    GetHostLicenseShareableCapacityAmount: "GetHostLicenseShareableCapacityAmount",
    listDeploymentsGeolocationInfo: "ListDeploymentsGeolocationInfo",
    getCmoDeploymentsSummary: "GetCmoDeploymentsSummary",
    listCmoSystems: "ListCmoSystems",
    getCmoSystemHealthStatus: "GetCmoSystemHealthStatus",
    getCmoSystemDetails: "GetCmoSystemDetails",
    getGmDeploymentClusterDetails: "GetGmDeploymentClusterDetails",
};

export const GalaxyMigrateMutationKeys = {
    configureVmwareHelper: "ConfigureVmwareHelper",
};
export const ConsumptionPlanningQueryKeys = {
    listConsumptionPlans: "ListConsumptionPlans",
    getConsumptionPlan: "GetConsumptionPlan",
};

export const ConsumptionPlanningMutationKeys = {
    createConsumptionPlan: "CreateConsumptionPlan",
    customizeConsumptionPlan: "CustomizeConsumptionPlan",
    deleteConsumptionPlan: "DeleteConsumptionPlan",
};

export const MigrateOpsQueryKeys = {
    listProjectOperations: "ListProjectOperations",
    getOperationDetails: "GetOperationDetails",
    getOperationConfig: "GetOperationConfig",
    listOperationLogLines: "ListOperationLogLines",
    getOperationDocs: "GetOperationDocs",
    getOperationTaskFacts: "GetOperationTaskFacts",
    listOperationConfigVersions: "ListOperationConfigVersions",
};

export const MigrateOpsMutationKeys = {
    createProjectOperation: "CreateProjectOperation",
    createOperationFromYaml: "CreateOperationFromYaml",
    retryOperation: "RetryOperation",
    approveTask: "ApproveTask",
    updateOperationConfig: "UpdateOperationConfig",
    cancelOperation: "CancelOperation",
    archiveOperation: "ArchiveOperation",
};

export const GmDeploymentQueryKeys = {
    getDeploymentStorageConfig: "GetDeploymentStorageConfig",
    getGalaxyMigrateLinks: "GetGalaxyMigrateLinks",
    getGmDeploymentIoStats: "GetGmDeploymentIoStats",
    getDeploymentMaxThroughput: "GetDeploymentMaxThroughput",
};

export const ProjectQueryKeys = {
    listAllLabels: "ListAllLabels",
    listMyProjects: "ListMyProjects",
    getProjectDetails: "GetProjectDetails",
    getMyProjectRole: "GetMyProjectRole",
};

export const ProjectMutationKeys = {
    createLabel: "CreateLabel",
    updateLabel: "UpdateLabel",
    deleteLabel: "DeleteLabel",
};

export const DeploymentMutationKeys = {
    addDeploymentLabel: "AddDeploymentLabel",
    removeDeploymentLabel: "RemoveDeploymentLabel",
    removeDeployment: "RemoveDeployment",
    removeAllOfflineDeployments: "RemoveAllOfflineDeployments",
    deleteHostLicense: "DeleteHostLicense",
};

export const CmoQueryKeys = {
    listCmoMigrationSessions: "ListCmoMigrationSessions",
    getCmoMigrationSessionDetails: "GetCmoMigrationSessionDetails",
    listCmoSessionVolumes: "ListCmoSessionVolumes",
    getCmoSessionVolumeDetails: "GetCmoSessionVolumeDetails",
    listCmoNexuses: "ListCmoNexuses",
    getCmoNexusDetails: "GetCmoNexusDetails",
    getCmoNexusDiscoveredPorts: "GetCmoNexusDiscoveredPorts",
    listCmoSourceVolumes: "ListCmoSourceVolumes",
    listCmoSystemEvents: "ListCmoSystemEvents",
    listCmoSystemWarnings: "ListCmoSystemWarnings",
    getCmoIoStats: "GetCmoIoStats",
    getCmoSystemWarningsSummary: "GetCmoSystemWarningsSummary",
};

export const ProjectSettingsQueryKeys = {
    listProjectMembers: "ListProjectMembers",
    listProjectActivities: "ListProjectActivities",
};

export const ProjectSettingsMutationKeys = {
    inviteNewProjectMembers: "InviteNewProjectMembers",
    removeProjectMember: "RemoveProjectMember",
    changeProjectMemberRole: "ChangeProjectMemberRole",
    leaveProject: "LeaveProject",
    updateProject: "UpdateProject",
    setProjectEmailNotifications: "SetProjectEmailNotifications",
    setProjectSupportPermissions: "SetProjectSupportPermissions",
    selectProjectProducts: "SelectProjectProducts",
};

export const IntegrationQueryKeys = {
    GetAllProjectIntegrations: "GetAllProjectIntegrations",
    GetEnabledIntegrationDetails: "GetEnabledIntegrationDetails",
    ListIntegrationModules: "ListIntegrationModules",
    GetDefaultVolumeParams: "GetDefaultVolumeParams",
    IsCapabilitySupported: "IsCapabilitySupported",
};

export const IntegrationMutationKeys = {
    UpdateProjectIntegration: "UpdateProjectIntegration",
    AddProjectIntegration: "AddProjectIntegration",
    RemoveProjectIntegration: "RemoveProjectIntegration",
    TestProjectIntegration: "TestProjectIntegration",
};
