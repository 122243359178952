import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { VendorAllocateVolumesStepProps } from "../GmAutoAllocationCommon";
import { Box, Button, Card, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AutoAlloc, BlockDeviceInfo } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { DiskIcon } from "../../../../common/CommonIcons";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import produce from "immer";
import { useMountEffect } from "../../../../common/hooks/hookslib";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";

// ======================
// CloudstackAllocateVolumesStep
// ======================
interface CloudstackDiskAssignmentConfig {
    capacity: number;
    diskOffering: string;
}

export const CloudstackAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const defaultParams = state.selectedIntegration.defaultVolumeParams?.cloudstack;
    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setCloudstack(new AutoAlloc.VolumeParams.CloudStack().setDiskOffering(defaultParams?.diskOffering));
        }
    });

    const getInitialDeviceAssignments = () => {
        const assignments: { [key: string]: CloudstackDiskAssignmentConfig } = {};
        for (let device of state.sourceDevices) {
            const blockDevice = device.source.getBlockDevice();
            assignments[blockDevice.getDeviceName()] = {
                capacity: blockDevice.getCapacity() / Math.pow(1024, 3),
                diskOffering: defaultParams?.diskOffering,
            };
        }
        return assignments;
    };

    const [deviceConfigs, setDeviceConfigs] = useState<{ [key: string]: CloudstackDiskAssignmentConfig }>(getInitialDeviceAssignments());

    const setDeviceCapacity = (deviceName: string, capacity: number) => {
        setDeviceConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[deviceName].capacity = capacity;
                } else {
                    draft[deviceName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const setDiskOffering = (deviceName: string, diskOffering: string) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].diskOffering = diskOffering;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getCloudstack().setDiskOffering(diskOffering);
    };

    const getHasError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        }
        for (let device in deviceConfigs) {
            const blockDevice = state.sourceDevices.find((d) => d.source.getBlockDevice().getDeviceName() === device);
            if (deviceConfigs[device].capacity < blockDevice?.source.getBlockDevice().getCapacity() / Math.pow(1024, 3)) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Destination Volumes will be allocated from the connected storage to match the following source volumes`}
            </Typography>
            <br />
            <List>
                {state.sourceDevices.map((device) => {
                    const blockDevice = device.source.getBlockDevice();
                    return (
                        <CloudstackDiskCard
                            key={blockDevice.getDeviceName()}
                            state={state}
                            blockDevice={blockDevice}
                            setDeviceCapacity={setDeviceCapacity}
                            currentDeviceCapacity={deviceConfigs[blockDevice.getDeviceName()].capacity}
                            setDiskOffering={setDiskOffering}
                            currentDiskOffering={deviceConfigs[blockDevice.getDeviceName()].diskOffering}
                        />
                    );
                })}
            </List>
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow} disabled={getHasError()}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});

// ======================
// CloudstackDiskCard
// ======================

interface CloudstackDiskCardProps {
    state: GmMigrationAutoAllocationState;
    blockDevice: BlockDeviceInfo;
    currentDeviceCapacity: number;
    setDeviceCapacity: (deviceName: string, capacity: number) => void;
    currentDiskOffering: string;
    setDiskOffering: (deviceName: string, diskOffering: string) => void;
}

const CloudstackDiskCard: React.FC<CloudstackDiskCardProps> = observer((p) => {
    const { state, blockDevice, currentDeviceCapacity, setDeviceCapacity, currentDiskOffering, setDiskOffering } = p;

    const defaultHelperText = "Set a custom disk size.";
    const [capacityHelperText, setCapacityHelperText] = useState(defaultHelperText);

    const getCapacityError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        } else {
            return currentDeviceCapacity < blockDevice.getCapacity() / Math.pow(1024, 3);
        }
    };

    return (
        <Card sx={{ mb: 2 }}>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 4,
                    }}
                >
                    <ListItem key={blockDevice.getDeviceName()}>
                        <ListItemIcon>
                            <DiskIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${blockDevice.getDeviceName()} (${blockDevice.getDeviceType()})`}
                            secondary={formatKnownDataType(blockDevice.getCapacity(), KnownDataType.CAPACITY)}
                        />
                    </ListItem>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 8,
                    }}
                >
                    <Grid container spacing={2} justifyContent={"flex-end"}>
                        <Grid>
                            <Box p={2}>
                                <TextField
                                    fullWidth
                                    label={"Disk Size"}
                                    variant={"filled"}
                                    error={getCapacityError()}
                                    value={currentDeviceCapacity}
                                    type={"number"}
                                    onChange={(e) => {
                                        setDeviceCapacity(blockDevice.getDeviceName(), Number(e.target.value));
                                        if (!state.allowSmallerDestinations && Number(e.target.value) < blockDevice.getCapacity() / Math.pow(1024, 3)) {
                                            setCapacityHelperText("Must be greater than or equal to source volume size.");
                                        } else {
                                            setCapacityHelperText(defaultHelperText);
                                        }
                                    }}
                                    helperText={capacityHelperText}
                                    InputProps={{ endAdornment: <Typography>{`GiB`}</Typography> }}
                                />
                            </Box>
                        </Grid>
                        <Grid>
                            <Box p={2}>
                                <TextField
                                    fullWidth
                                    label={"Disk Offering"}
                                    variant={"filled"}
                                    value={currentDiskOffering}
                                    onChange={(e) => {
                                        setDiskOffering(blockDevice.getDeviceName(), e.target.value);
                                    }}
                                    helperText={"Specify the disk offering to use"}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
});
