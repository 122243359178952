import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, Divider, ListSubheader, Theme, Typography } from "@mui/material";
import enUS from "date-fns/locale/en-US";
import { dateFnsLocalizer, Calendar, Components, DateLocalizer, Event, Formats, DateRange, Culture } from "react-big-calendar";
import { MigrationSession } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import "./qosCalendar.css";
import {
    format,
    parse,
    startOfWeek,
    getDay,
    nextMonday,
    nextTuesday,
    nextWednesday,
    nextThursday,
    nextFriday,
    nextSaturday,
    previousSunday,
    previousMonday,
    previousTuesday,
    previousWednesday,
    previousThursday,
    previousFriday,
} from "date-fns";
import { getImpactLevelConfig } from "../../CmcMigrationCommon";
import { useIsDesktop } from "../../../layout/MainLayout";
const locales = {
    "en-US": enUS,
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

// ======================
// GalaxyMigrateMigrationSessionDetailsQosCalendar
// ======================

interface GalaxyMigrateMigrationSessionDetailsQosCalendarProps {
    qosSchedule: MigrationSession.ConfigParams.QosSchedule.AsObject;
    timezone: string;
}

export const GalaxyMigrateMigrationSessionDetailsQosCalendar: React.FC<GalaxyMigrateMigrationSessionDetailsQosCalendarProps> = observer((p) => {
    const { qosSchedule, timezone } = p;
    console.log("qosSchedule", qosSchedule);
    const components = useMemo(
        (): Components => ({
            toolbar: () => null,
        }),
        []
    );

    const formats = useMemo(
        (): Formats => ({
            dayFormat: (date: Date, culture: string, localizer: DateLocalizer) => localizer.format(date, "EEEE", culture),
            eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) => {
                const endDisplayDate = format(new Date(range.end).setMinutes(range.end.getMinutes() + 1), "hh:mm aa");
                return `${format(range.start, "hh:mm aa")}-${endDisplayDate}`;
            },
        }),
        []
    );

    return (
        <Box pt={4}>
            <Card sx={{ color: (t: Theme) => t.palette.text.secondary }}>
                <ListSubheader>iQOS Schedule (in {timezone})</ListSubheader>
                <Divider />
                <Calendar
                    formats={formats}
                    components={components}
                    selectable={false}
                    getNow={() => null}
                    events={getEventsFromQosSchedule(qosSchedule)}
                    views={["week"]}
                    defaultDate={new Date()}
                    defaultView={"week"}
                    style={{ height: 1250 }}
                    localizer={localizer}
                    startAccessor={"start"}
                    endAccessor={"end"}
                    tooltipAccessor={(event) => `${getImpactLevelConfig(event?.resource?.qosLevel)?.title} iQOS Level`}
                />
            </Card>
        </Box>
    );
});

export const getEventFromInterval = (dayOfWeek: number, interval: MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.AsObject): Event => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDate = today.getDate();
    const currentDay = today.getDay();

    let targetYear;
    let targetMonth;
    let targetDate;

    if (dayOfWeek === 0) {
        targetYear = currentDay === dayOfWeek ? currentYear : previousSunday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : previousSunday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : previousSunday(today).getDate();
    }
    if (dayOfWeek === 1) {
        targetYear = currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextMonday(today).getFullYear() : previousMonday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextMonday(today).getMonth() : previousMonday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextMonday(today).getDate() : previousMonday(today).getDate();
    }
    if (dayOfWeek === 2) {
        targetYear = currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextTuesday(today).getFullYear() : previousTuesday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextTuesday(today).getMonth() : previousTuesday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextTuesday(today).getDate() : previousTuesday(today).getDate();
    }
    if (dayOfWeek === 3) {
        targetYear =
            currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextWednesday(today).getFullYear() : previousWednesday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextWednesday(today).getMonth() : previousWednesday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextWednesday(today).getDate() : previousWednesday(today).getDate();
    }
    if (dayOfWeek === 4) {
        targetYear =
            currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextThursday(today).getFullYear() : previousThursday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextThursday(today).getMonth() : previousThursday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextThursday(today).getDate() : previousThursday(today).getDate();
    }
    if (dayOfWeek === 5) {
        targetYear = currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextFriday(today).getFullYear() : previousFriday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextFriday(today).getMonth() : previousFriday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextFriday(today).getDate() : previousFriday(today).getDate();
    }
    if (dayOfWeek === 6) {
        targetYear = currentDay === dayOfWeek ? currentYear : nextSaturday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : nextSaturday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : nextSaturday(today).getDate();
    }

    const start = new Date(targetYear, targetMonth, targetDate, interval.start / 60, interval.start % 60);
    const end = new Date(targetYear, targetMonth, targetDate, interval.end / 60, interval.end % 60);
    const endDisplayDate = format(new Date(end).setMinutes(end.getMinutes() + 1), "hh:mm aa");

    return {
        start: start,
        end: end,
        allDay: false,
        title: (
            <Box pt={1}>
                <Typography variant={"caption"}>
                    {format(start, "hh:mm aa")}-{endDisplayDate}
                </Typography>
                <Box height={10} />
                <Typography fontWeight={700}>{getImpactLevelConfig(interval.qosLevel).title}</Typography>
                <Typography variant={"caption"}>iQOS Level</Typography>
            </Box>
        ),
        resource: interval,
    };
};

const getEventsFromQosSchedule = (qosSchedule: MigrationSession.ConfigParams.QosSchedule.AsObject): Event[] => {
    const events: Event[] = [];

    if (!!qosSchedule.sunday.intervalsList) {
        for (let interval of qosSchedule.sunday.intervalsList) {
            const event = getEventFromInterval(0, interval);
            events.push(event);
        }
    }
    if (!!qosSchedule.monday.intervalsList) {
        for (let interval of qosSchedule.monday.intervalsList) {
            const event = getEventFromInterval(1, interval);
            events.push(event);
        }
    }
    if (!!qosSchedule.tuesday.intervalsList) {
        for (let interval of qosSchedule.tuesday.intervalsList) {
            const event = getEventFromInterval(2, interval);
            events.push(event);
        }
    }
    if (!!qosSchedule.wednesday.intervalsList) {
        for (let interval of qosSchedule.wednesday.intervalsList) {
            const event = getEventFromInterval(3, interval);
            events.push(event);
        }
    }
    if (!!qosSchedule.thursday.intervalsList) {
        for (let interval of qosSchedule.thursday.intervalsList) {
            const event = getEventFromInterval(4, interval);
            events.push(event);
        }
    }
    if (!!qosSchedule.friday.intervalsList) {
        for (let interval of qosSchedule.friday.intervalsList) {
            const event = getEventFromInterval(5, interval);
            events.push(event);
        }
    }
    if (!!qosSchedule.saturday.intervalsList) {
        for (let interval of qosSchedule.saturday.intervalsList) {
            const event = getEventFromInterval(6, interval);
            events.push(event);
        }
    }

    return events;
};
