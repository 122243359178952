// ======================
// MigrateOpsConfigurationsTab
// ======================

import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, IconButton, Link, Stack, Typography } from "@mui/material";
import { PaginationState } from "@tanstack/react-table";
import { OperationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/operation_status_pb";
import { OperationConfigVersion, OperationDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/operation_pb";
import { UpdateOperationConfig } from "gc-web-proto/galaxycompletepb/apipb/operation_api_pb";
import hljs from "highlight.js/lib/core";
import React, { useState } from "react";
import { MdHistory } from "react-icons/md";
import Editor from "react-simple-code-editor";
import { CodeCard, DarkFlatCard, DarkFlatOutlinedCard } from "../../../common/card/DarkCard";
import { SelectableBox } from "../../../common/card/SelectableCard";
import { QueryListTable } from "../../../common/table/QueryListTable";
import { BlackTooltip } from "../../../common/tooltip/ColorTooltip";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { getUserFullNameFromObject } from "../../settings/ProjectUsers";
import { useGetMigrateOpConfig, useListOperationConfigVersions, useUpdateOperationConfig } from "../migrate_ops_hooks";
import { IoIosArrowRoundBack } from "react-icons/io";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
interface MigrateOpsConfigurationsTabProps {
    data: OperationDetails.AsObject;
}

export const MigrateOpsConfigurationsTab: React.FC<MigrateOpsConfigurationsTabProps> = (p) => {
    const { data } = p;
    const configRes = useGetMigrateOpConfig(data.info.id);
    if (!data?.info?.id) {
        return null;
    }
    return (
        <QueryResultWrapper queryResult={configRes}>
            <ConfigYamlDisplay yaml={configRes.data?.yamlString} opStatus={data?.info.status} opId={data?.info.id} />
        </QueryResultWrapper>
    );
};

interface ConfigYamlDisplayProps {
    yaml: string;
    opStatus: OperationStatus.OperationStatus;
    opId: number;
}

function ConfigYamlDisplay(p: ConfigYamlDisplayProps) {
    const [editOn, setEditOn] = useState(false);
    const [editValue, setEditValue] = useState("");
    const html = hljs.highlight(p.yaml, { language: "yaml" }).value;
    const updateConfig = useUpdateOperationConfig();
    const globalDialogState = useGlobalDialogState();
    const configHistoryDialogState = useDialogState();
    const onSave = async () => {
        if (p.opStatus !== OperationStatus.OperationStatus.PENDING) {
            const confirmed = await globalDialogState.addConfirmDialog({
                title: "Confirm Configuration Update",
                message: (
                    <Box>
                        <Typography>{"WARNING! Operation has already been started."}</Typography>
                        <br />
                        <Typography>
                            {`This operation has already been partially executed.
                            While you can still edit the operation configuration,
                            updating the configuration now may have`}{" "}
                            <b>{`conflicts`}</b>{" "}
                            {`with the operation tasks that have already been executed.
                        This could result in unwanted consequences or failure.`}
                        </Typography>
                        <br />
                        <Typography>
                            {`Depending on the operation recipe and the fields that are changed, 
                        some changes may be ignored if they have already been executed.`}
                        </Typography>
                        <br />
                        <Typography>{"If you require additional assistance, please contact Cirrus Data Support."}</Typography>
                    </Box>
                ),
                typeToConfirm: "UPDATE",
            });
            if (confirmed) {
                const req = new UpdateOperationConfig.Request().setYamlString(editValue).setOperationId(p.opId);
                await updateConfig.mutateAsync(req);
                setEditOn(false);
            }
        }
    };

    const configActions = (
        <Stack direction={"row"} spacing={1}>
            <OperatorView>
                <Button
                    variant={"contained"}
                    disabled={editOn || p.opStatus === OperationStatus.OperationStatus.COMPLETED}
                    onClick={() => {
                        setEditValue(p.yaml);
                        setEditOn(true);
                    }}
                >
                    {"Edit Configuration"}
                </Button>
                <BlackTooltip title={"View Configuration History"}>
                    <IconButton onClick={configHistoryDialogState.open}>
                        <MdHistory />
                    </IconButton>
                </BlackTooltip>
            </OperatorView>
        </Stack>
    );

    return (
        <Box pt={2}>
            <Card>
                <CardHeader
                    title={"MigrateOps Configuration"}
                    subheader={`The following configuration is currently active for this operation.`}
                    action={configActions}
                />
                <CardContent>
                    {editOn ? (
                        <>
                            <DarkFlatOutlinedCard sx={{ padding: 2, maxHeight: "55vh", overflow: "auto" }}>
                                <Editor
                                    value={editValue}
                                    onValueChange={(v) => setEditValue(v)}
                                    placeholder={"Enter your MigrateOps Configuration YAML here..."}
                                    highlight={(code) => hljs.highlight(code, { language: "yaml" }).value}
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono","Consolas","Menlo", monospace',
                                        fontSize: 13,
                                        minHeight: "50vh",
                                    }}
                                />
                            </DarkFlatOutlinedCard>
                            <Stack direction={"row"} spacing={1} pt={2}>
                                <Button variant={"contained"} onClick={onSave}>
                                    {"Save"}
                                </Button>
                                <Button variant={"outlined"} color={"neutral"} onClick={() => setEditOn(false)}>
                                    {"Cancel"}
                                </Button>
                            </Stack>
                        </>
                    ) : (
                        <pre>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </pre>
                    )}
                </CardContent>
            </Card>
            {configHistoryDialogState.isOpen && <ConfigHistoryDialog opId={p.opId} dialogState={configHistoryDialogState} />}
        </Box>
    );
}

interface ConfigHistoryDialogProps {
    opId: number;
    dialogState: DialogState;
}

const ConfigHistoryDialog: React.FC<ConfigHistoryDialogProps> = (p) => {
    const { opId, dialogState } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    const [selectedVersion, setSelectedVersion] = useState<number | null>(null);
    const configVersions = useListOperationConfigVersions(opId, pageIndex, pageSize);
    const fullScreen = useShouldDialogFullScreen();

    const getHtml = () => {
        if (selectedVersion) {
            return hljs.highlight(configVersions.data?.itemsList.find((v) => v.version === selectedVersion)?.yamlString, { language: "yaml" }).value;
        }
        return null;
    };

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} fullWidth maxWidth={"lg"}>
            <DialogTopBar divider title={"Configuration Version History"} dialogState={dialogState} />
            <DialogContent>
                {!selectedVersion && (
                    <QueryListTable
                        data={configVersions.data?.itemsList}
                        listComponent={ConfigHistoryListItem}
                        pagination={{ pageIndex, pageSize }}
                        pageCount={configVersions.data?.pagerMeta.totalPages}
                        setPagination={setPagination}
                        error={configVersions.error}
                        isError={configVersions.isError}
                        isLoading={configVersions.isLoading}
                        refetch={configVersions.refetch}
                        componentProps={{
                            onClick: (version: number) => {
                                setSelectedVersion(version);
                            },
                            selectedVersion: selectedVersion,
                        }}
                    />
                )}
                {selectedVersion && (
                    <Box>
                        <Link variant={"body2"} sx={{ color: "text.secondary" }} onClick={() => setSelectedVersion(null)}>
                            {"← Back to Version History"}
                        </Link>
                        <Typography variant={"body1"} fontWeight={600}>{`Version ${selectedVersion}`}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"} sx={{ fontStyle: "italic" }}>{`${formatKnownDataType(
                            configVersions.data?.itemsList.find((v) => v.version === selectedVersion)?.createdAt,
                            KnownDataType.DATE
                        )} by ${getUserFullNameFromObject(
                            configVersions.data?.itemsList.find((v) => v.version === selectedVersion)?.createdByUser
                        )}`}</Typography>

                        <DarkFlatCard sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 2 }}>
                            <pre>
                                <div dangerouslySetInnerHTML={{ __html: getHtml() }} />
                            </pre>
                        </DarkFlatCard>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

interface ConfigHistoryListItemProps {
    data: OperationConfigVersion.AsObject;
    componentProps?: {
        selectedVersion: number | null;
        onClick: (version: number) => void;
    };
}

const ConfigHistoryListItem: React.FC<ConfigHistoryListItemProps> = (p) => {
    const { data, componentProps } = p;
    return (
        <Box mb={2}>
            <SelectableBox selected={false} onSelect={() => componentProps?.onClick(data.version)}>
                <CardContent>
                    <Typography variant={"body1"} fontWeight={600}>{`Version ${data.version}`}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"} sx={{ fontStyle: "italic" }}>{`${formatKnownDataType(
                        data.createdAt,
                        KnownDataType.DATE
                    )} by ${getUserFullNameFromObject(data.createdByUser)}`}</Typography>
                </CardContent>
            </SelectableBox>
        </Box>
    );
};
