// ======================
// EditEnabledIntegration
// ======================

import { observer } from "mobx-react-lite";

import { useAppServices } from "../../app/services";
import { IntegrationForm } from "./IntegrationForm";
import { useNavigateToIntegrationDetails } from "../IntegrationsCommon";
import { FormikValues } from "formik";
import { useInitData } from "../../core/data/DataLoaderHooks";
import { useParams } from "react-router-dom";
import React from "react";
import { IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { IntegrationParams } from "gc-web-proto/galaxycompletepb/apipb/integration_api_pb";
import { useGmAutoAllocationState } from "../../cmcMigration/autoallocation/GmAutoAllocationCommon";
import { v4 as uuid } from "uuid";
import { useGetEnabledIntegrationDetails, useUpdateProjectIntegration } from "../integration_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";

interface EditEnabledIntegrationProps {}

export const EditEnabledIntegration: React.FC<EditEnabledIntegrationProps> = observer((p) => {
    const { deploymentService } = useAppServices();

    const { integrationId } = useParams();

    const goBackToIntegrationDetails = useNavigateToIntegrationDetails(Number(integrationId));

    const autoAllocState = useGmAutoAllocationState();
    const updateProjectIntegration = useUpdateProjectIntegration();
    const integrationDetails = useGetEnabledIntegrationDetails(Number(integrationId));

    const onSubmit = async (
        module: IntegrationModule,
        friendlyName: string,
        deploymentId: string,
        connParams?: IntegrationParams.ConnParams,
        verboseLogging?: boolean,
        updateCredentials?: boolean
    ) => {
        const statusId = uuid();
        if (!!deploymentId) {
            const deploymentDetails = await deploymentService.fetchDeploymentDetails(deploymentId);
            autoAllocState.setDeployment(deploymentDetails);
            autoAllocState.setNewStatusId(statusId);
            autoAllocState.setShowProgressDialog(true);
            await updateProjectIntegration.mutateAsync({
                id: Number(integrationId),
                friendlyName,
                statusId,
                deploymentId,
                connParams,
                verboseLogging,
                updateCredentials,
            });

            await autoAllocState.waitForProgressDialogToClose();
        } else {
            await updateProjectIntegration.mutateAsync({
                id: Number(integrationId),
                friendlyName,
                statusId,
                deploymentId,
                connParams,
                verboseLogging,
                updateCredentials,
            });
        }
    };

    return (
        <QueryResultWrapper queryResult={integrationDetails}>
            <IntegrationForm
                currentIntegration={integrationDetails.data?.info}
                onCancel={goBackToIntegrationDetails}
                onSubmit={onSubmit}
                onSubmitted={goBackToIntegrationDetails}
            />
        </QueryResultWrapper>
    );
});
