import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AutoAlloc, BlockDeviceInfo } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { DiskIcon } from "../../../../common/CommonIcons";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import produce from "immer";
import { useMountEffect } from "../../../../common/hooks/hookslib";
import { QuickTipButton } from "../../../help/HelpCommon";

// ======================
// OvirtAllocateVolumesStep
// ======================
interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

interface OvirtDiskAssignmentConfig {
    capacity: number;
    storageDomain: string;
    sparse: boolean;
    diskInterface: AutoAlloc.VolumeParams.Ovirt.DiskInterface;
    wipeAfterDelete: boolean;
    incrementalBackup: boolean;
    shareable: boolean;
}

export const OvirtAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const defaultParams = state.selectedIntegration.defaultVolumeParams.ovirt;
    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setOvirt(
                new AutoAlloc.VolumeParams.Ovirt()
                    .setSparse(defaultParams.sparse)
                    .setStorageDomain(defaultParams.storageDomain)
                    .setInterface(defaultParams.pb_interface)
                    .setWipeAfterDelete(defaultParams.wipeAfterDelete)
                    .setIncrementalBackup(defaultParams.incrementalBackup)
                    .setShareable(defaultParams.shareable)
            );
        }
    });

    const getInitialDeviceAssignments = () => {
        const assignments: { [key: string]: OvirtDiskAssignmentConfig } = {};
        for (let device of state.sourceDevices) {
            const blockDevice = device.source.getBlockDevice();
            assignments[blockDevice.getDeviceName()] = {
                capacity: blockDevice.getCapacity() / Math.pow(1024, 3),
                storageDomain: defaultParams.storageDomain,
                sparse: defaultParams.sparse,
                diskInterface: defaultParams.pb_interface,
                wipeAfterDelete: defaultParams.wipeAfterDelete,
                incrementalBackup: defaultParams.incrementalBackup,
                shareable: defaultParams.shareable,
            };
        }
        return assignments;
    };

    const [deviceConfigs, setDeviceConfigs] = useState<{
        [key: string]: OvirtDiskAssignmentConfig;
    }>(getInitialDeviceAssignments());

    const setDeviceCapacity = (deviceName: string, capacity: number) => {
        setDeviceConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[deviceName].capacity = capacity;
                } else {
                    draft[deviceName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const setStorageDomain = (deviceName: string, storageDomain: string) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].storageDomain = storageDomain;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getOvirt().setStorageDomain(storageDomain);
    };

    const setInterface = (deviceName: string, diskInterface: AutoAlloc.VolumeParams.Ovirt.DiskInterface) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].diskInterface = diskInterface;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getOvirt().setInterface(diskInterface);
    };

    const setSparse = (deviceName: string, sparse: boolean) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].sparse = sparse;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getOvirt().setSparse(sparse);
    };

    const setWipeAfterDelete = (deviceName: string, wipeAfterDelete: boolean) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].wipeAfterDelete = wipeAfterDelete;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getOvirt().setWipeAfterDelete(wipeAfterDelete);
    };

    const setIncrementalBackup = (deviceName: string, incrementalBackup: boolean) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].incrementalBackup = incrementalBackup;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getOvirt().setIncrementalBackup(incrementalBackup);
    };

    const setShareable = (deviceName: string, shareable: boolean) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName].shareable = shareable;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocParams.getOvirt().setShareable(shareable);
    };

    const getHasError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        }
        for (let device in deviceConfigs) {
            const blockDevice = state.sourceDevices.find((d) => d.source.getBlockDevice().getDeviceName() === device);
            if (deviceConfigs[device].capacity < blockDevice?.source.getBlockDevice().getCapacity() / Math.pow(1024, 3)) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Destination Volumes will be allocated from the connected storage to match the following source volumes`}
            </Typography>
            <br />
            <List>
                {state.sourceDevices.map((device) => {
                    const blockDevice = device.source.getBlockDevice();
                    return (
                        <OvirtDiskCard
                            state={state}
                            blockDevice={blockDevice}
                            setInterface={setInterface}
                            currentInterface={deviceConfigs[blockDevice.getDeviceName()].diskInterface}
                            setDeviceCapacity={setDeviceCapacity}
                            currentDeviceCapacity={deviceConfigs[blockDevice.getDeviceName()].capacity}
                            currentStorageDomain={deviceConfigs[blockDevice.getDeviceName()].storageDomain}
                            setStorageDomain={setStorageDomain}
                            currentSparse={deviceConfigs[blockDevice.getDeviceName()].sparse}
                            setSparse={setSparse}
                            currentWipeAfterDelete={deviceConfigs[blockDevice.getDeviceName()].wipeAfterDelete}
                            setWipeAfterDelete={setWipeAfterDelete}
                            currentIncrementalBackup={deviceConfigs[blockDevice.getDeviceName()].incrementalBackup}
                            setIncrementalBackup={setIncrementalBackup}
                            currentShareable={deviceConfigs[blockDevice.getDeviceName()].shareable}
                            setShareable={setShareable}
                        />
                    );
                })}
            </List>
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow} disabled={getHasError()}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});

// ======================
// OvirtDiskCard
// ======================

interface OvirtDiskCardProps {
    state: GmMigrationAutoAllocationState;
    blockDevice: BlockDeviceInfo;
    currentDeviceCapacity: number;
    setDeviceCapacity: (deviceName: string, capacity: number) => void;
    currentStorageDomain: string;
    setStorageDomain: (deviceName: string, storageDomain: string) => void;
    currentInterface: AutoAlloc.VolumeParams.Ovirt.DiskInterface;
    setInterface: (deviceName: string, diskInterface: AutoAlloc.VolumeParams.Ovirt.DiskInterface) => void;
    currentSparse: boolean;
    setSparse: (deviceName: string, sparse: boolean) => void;
    currentWipeAfterDelete: boolean;
    setWipeAfterDelete: (deviceName: string, wipeAfterDelete: boolean) => void;
    currentIncrementalBackup: boolean;
    setIncrementalBackup: (deviceName: string, incrementalBackup: boolean) => void;
    currentShareable: boolean;
    setShareable: (deviceName: string, shareable: boolean) => void;
}

const OvirtDiskCard: React.FC<OvirtDiskCardProps> = observer((p) => {
    const {
        state,
        blockDevice,
        currentDeviceCapacity,
        setDeviceCapacity,
        currentStorageDomain,
        setStorageDomain,
        currentInterface,
        setInterface,
        currentSparse,
        setSparse,
        currentWipeAfterDelete,
        setWipeAfterDelete,
        currentIncrementalBackup,
        setIncrementalBackup,
        currentShareable,
        setShareable,
    } = p;

    const defaultHelperText = "Set a custom disk size.";

    const [capacityHelperText, setCapacityHelperText] = useState(defaultHelperText);

    const getCapacityError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        } else {
            return currentDeviceCapacity < blockDevice.getCapacity() / Math.pow(1024, 3);
        }
    };

    return (
        <Card sx={{ mb: 2 }}>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <ListItem key={blockDevice.getDeviceName()}>
                        <ListItemIcon>
                            <DiskIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${blockDevice.getDeviceName()} (${blockDevice.getDeviceType()})`}
                            secondary={formatKnownDataType(blockDevice.getCapacity(), KnownDataType.CAPACITY)}
                        />
                    </ListItem>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 9,
                    }}
                >
                    <Grid container spacing={2} p={2} justifyContent={"flex-end"}>
                        <Grid
                            size={{
                                xs: 12,
                                lg: 6,
                            }}
                        >
                            <Box>
                                <TextField
                                    fullWidth
                                    label={"Disk Size"}
                                    variant={"filled"}
                                    error={getCapacityError()}
                                    value={currentDeviceCapacity}
                                    type={"number"}
                                    onChange={(e) => {
                                        setDeviceCapacity(blockDevice.getDeviceName(), Number(e.target.value));
                                        if (!state.allowSmallerDestinations && Number(e.target.value) < blockDevice.getCapacity() / Math.pow(1024, 3)) {
                                            setCapacityHelperText("Must be greater than or equal to source volume size.");
                                        } else {
                                            setCapacityHelperText(defaultHelperText);
                                        }
                                    }}
                                    helperText={capacityHelperText}
                                    InputProps={{ endAdornment: <Typography>{`GiB`}</Typography> }}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                lg: 6,
                            }}
                        >
                            <Box>
                                <TextField
                                    fullWidth
                                    label={"Storage Domain"}
                                    variant={"filled"}
                                    value={currentStorageDomain}
                                    onChange={(e) => {
                                        setStorageDomain(blockDevice.getDeviceName(), e.target.value);
                                    }}
                                    helperText={"If not specified, volume will be created in the same storage domain as the VM."}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                lg: 6,
                            }}
                        >
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={true}
                                            value={currentSparse}
                                            onChange={(e) => {
                                                setSparse(blockDevice.getDeviceName(), e.target.checked);
                                            }}
                                        />
                                    }
                                    label={
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>{"Sparse"}</Typography>
                                            <QuickTipButton title={"If checked, sparse provisioning will be used when creating the disk."} />
                                        </Stack>
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                lg: 6,
                            }}
                        >
                            <Box>
                                <FormControl fullWidth>
                                    <InputLabel id={"interface-label"} variant={"filled"}>
                                        {`Interface`}
                                    </InputLabel>
                                    <Select
                                        variant={"filled"}
                                        labelId={"interface-label"}
                                        id={"interface-select"}
                                        value={currentInterface}
                                        label={"Interface"}
                                        onChange={(e) => {
                                            setInterface(blockDevice.getDeviceName(), e.target.value as AutoAlloc.VolumeParams.Ovirt.DiskInterface);
                                        }}
                                    >
                                        <MenuItem value={AutoAlloc.VolumeParams.Ovirt.DiskInterface.VIRTIO}>{"Virtio"}</MenuItem>
                                        <MenuItem value={AutoAlloc.VolumeParams.Ovirt.DiskInterface.VIRTIO_SCSI}>{"Virtio SCSI"}</MenuItem>
                                    </Select>
                                    <FormHelperText>
                                        {"select how the disk should be attached to the VM. if not specified, the disk will be attached via scsi."}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                lg: 6,
                            }}
                        >
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={true}
                                            value={currentWipeAfterDelete}
                                            onChange={(e) => {
                                                setWipeAfterDelete(blockDevice.getDeviceName(), e.target.checked);
                                            }}
                                        />
                                    }
                                    label={<Typography>{"Wipe After Delete"}</Typography>}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                lg: 6,
                            }}
                        >
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={false}
                                            value={currentIncrementalBackup}
                                            onChange={(e) => {
                                                setIncrementalBackup(blockDevice.getDeviceName(), e.target.checked);
                                            }}
                                        />
                                    }
                                    label={<Typography>{"Incremental Backup"}</Typography>}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                lg: 6,
                            }}
                        >
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={false}
                                            value={currentShareable}
                                            onChange={(e) => {
                                                setShareable(blockDevice.getDeviceName(), e.target.checked);
                                            }}
                                        />
                                    }
                                    label={<Typography>{"Shareable"}</Typography>}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
});
